.Template-Editing-Slot {
  margin: 0 9px;
  box-sizing: border-box;
  position: absolute;
  width: calc(100% - 18px);
  border-width: .5px;
  left: 0;
  right: 0;
  cursor: pointer;


  &__time {
    position: absolute;
    top: 15px;
    font-size: 12px;
    line-height: 120%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
  }

  &__container {
    width: 100%;
  }

  $colorsType: (
          'call': 'orange',
          'video': 'purple',
          'physical': 'blue',
  );

  $slot: &;

  @each $type, $color in $colorsType {
    &--#{$type} &__container {
      background: var(--color-#{$color}-basic);
      border-color: var(--color-#{$color}-basic);
      opacity: .2;
    }
    &--#{$type} &__time {
      color: var(--color-#{$color}-basic);
    }

  }


}