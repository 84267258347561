[aria-label='EditSlotTemplate'] {
  min-width: 500px;
  width: max-content;
  padding: 0;
}

.TemplateEditForm {
  min-width: 400px;

  &__content {
    padding: 32px;
  }

  &__footer {
    padding: 0 32px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    min-height: 96px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid var(--color-dark-lighten);

    &__buttons {
      display: flex;

      &__button {
        &:not(:last-child) {
          margin-right: 6px;
        }
        &--delete {
          width: max-content;
        }
      }
    }
  }

  &__type {
    width: max-content;
    padding-left: 21px;
    padding-right: 21px;
    background: transparent;
    font-weight: normal;

    &:not(:last-child) {
      margin-right: 8px;
    }

    $button: &;

    &--physical {
      &#{$button}:hover, &#{$button}--active {
        background: var(--color-blue-lighten);
      }

      color: var(--color-blue-basic);
    }

    &--call {
      &#{$button}:hover, &#{$button}--active {
        background: var(--color-orange-lighten);
      }

      color: var(--color-orange-basic);
    }

    &--video {
      &#{$button}:hover, &#{$button}--active {
        background: var(--color-purple-lighten);
      }

      color: var(--color-purple-basic);
    }
  }

  &__time {
    font-size: 16px;
    font-weight: bold;
    color: var(--color-dark-basic);
    margin-top: 16px;
    &__start {
      margin-left: 10px;
    }
    &__start, &__end {
      height: 44px;
      border-radius: 4px;
      &:hover, &:focus-within {
        background: var(--color-grey-lighten);
      }
    }
    & input {
      width: 50px;
      border: none;
      font-size: 16px;
      font-weight: bold;
      color: var(--color-dark-basic);
      outline: none;
    }

    &__selected {
      & > button {
        background: none;
        border: none;
        outline: none;
        color: var(--color-blue-basic);
        font-size: 14px;
        & .icon {
          font-size: 16px;
          margin-right: 3px;
        }
      }

    }
  }

}