.pageSchedule {
  min-width: 1640px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px;
  }
  &__content {
    padding: 0 32px;
  }
}

[data-reach-dialog-overlay] {
  z-index: 3000;
}

[data-reach-dialog-content][aria-label="Appointment Form"] {
  width: max-content;
  padding: 0;
}
