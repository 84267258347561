
.myhealthLogo {
  display: flex;
  align-items: center;
  &__text {
    margin-left: 10px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.06em;
    color: #565656;
  }
}