.ContentAppointment {
  &__header {
    display: flex;
    align-items: center;
    padding: 54px 32px 12px 33px;

    &__backButton {
      margin-top: 6px;
    }

    &__title {
      display: flex;
      flex-direction: column;
    }

    &__menu {
      margin-left: auto;
      font-weight: 300;
      font-size: 30px;
      line-height: 120%;
      color: var(--color-grey-basic);
      cursor: pointer;
      align-self: normal;
      display: flex;
      align-items: center;
      height: min-content;

      &__button {
        font-weight: 300;
        font-size: 30px;
        line-height: 120%;
        color: var(--color-grey-basic);
        cursor: pointer;
        outline: none;
        border: none;
        background: none;

        &:hover {
          color: var(--color-blue-basic);
        }

        &--history {
          margin-bottom: -6px;
        }
      }

      &__history {
        display: flex;
        flex-direction: column;
        padding: 6px 19px;
        font-size: 12px;
        line-height: 130.9%;
        font-weight: bold;
        color: var(--color-grey-basic);
      }

      &__restore {
        font-size: 30px;
        line-height: 120%;
        display: flex;
        height: min-content;
        flex-wrap: nowrap;
        margin-top: 4px;
        margin-right: 8px;

        &:hover {
          color: var(--color-blue-basic)
        }
      }
    }

    &__title {
      margin: 0;
    }

    &__backButton {
      font-size: 32px;
      color: var(--color-grey-basic);
      margin-right: 15px;
      cursor: pointer;

      &:hover {
        color: var(--color-dark-basic);
      }
    }
  }

  &__content {
    padding: 0 32px 0 75px;
  }


  &__appointment {
    &__status {
      &--canceled {
        color: var(--color-red-basic);
      }
    }


    &__attachments__container {
      display: flex;
    }

    &__attachment {
      border: 1px solid var(--color-blue-lighten);
      padding: 7px 15px;
      border-radius: 4px;
      font-weight: bold;
      font-size: 16px;
      color: var(--color-blue-basic);
      display: flex;
      margin-right: 1em;

      &__fileName {
        color: var(--color-blue-basic);
        cursor: pointer;
      }

      &__fileSize {
        margin-left: 5px;
        font-weight: bold;
        color: var(--color-grey-basic);
      }

      &__delete {
        display: inline-block;
        margin-left: 15px;
      }

      &__upload__button {
        //display: inline-block;
        margin-left: auto;
      }

      &__label {
        font-weight: bold;
        font-size: 16px;
        color: var(--color-blue-basic);
      }
    }
  }

  &__notes {
    background: #FFFFFF;
    height: 100%;
    padding: 43px 37px;
    margin-top: 58px;
    border: 2px solid var(--color-blue-basic);
    box-shadow: 0px 9px 80px rgba(18, 131, 235, 0.07), 0px 3.75998px 33.4221px rgba(0, 0, 0, 0.0404888), 0px 2.01027px 17.869px rgba(0, 0, 0, 0.0327272), 0px 1.12694px 10.0172px rgba(0, 0, 0, 0.0276888), 0px 0.598509px 5.32008px rgba(0, 0, 0, 0.0231048), 0px 0.249053px 2.21381px rgba(0, 0, 0, 0.0169942);
    border-radius: 4px;
    display: flex;
    flex-direction: column;

    &__form {
      display: flex;
      flex-direction: column;
    }

    &__item {
      margin-top: 27px;
      color: var(--color-grey-basic);

      &--symptoms {
        & .SymptomsSelector__prefix {
          display: none;
        }
      }

      &--hasTerm, &:focus-within {
        color: var(--color-dark-basic);
      }

      &__title {
        font-weight: bold;
        font-size: 16px;
        line-height: 130.9%;
        user-select: none;
        margin-bottom: 4px;
      }

      & [data-textarea-notes] {
        width: 100%;
        box-sizing: border-box;
        display: block;
        max-width: 100%;
        font-smoothing: subpixel-antialiased;
      }
    }

    &__send {
      margin-top: 20px;
      margin-left: auto;
    }
  }

  &__loader {
    width: 100%;
    height: calc(100vh - var(--height-to-top));
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__description {
    margin-bottom: 48px;
    color: var(--color-dark-basic);

    &__type {
      &--physical {
        color: var(--color-blue-basic)
      }

      &--video {
        color: var(--color-purple-basic)
      }

      &--call {
        color: var(--color-orange-basic)
      }
    }
  }

  &__members {
    display: grid;
    grid-template-columns: repeat(4, max-content);
    grid-gap: 16px;
    margin-top: 47px;
    color: var(--color-dark-basic);
    font-size: 16px;
    line-height: 130.9%;
  }

  &__information {
    margin-top: 58px;
    color: var(--color-dark-basic);
    font-weight: bold;

    &__field {
      &:not(first-child) {
        margin-top: 32px;
      }

      &__icon {
        margin-right: 27px;
      }
    }
  }
}
