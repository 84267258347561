.navHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  padding: 17px 30px;
  box-sizing: border-box;
  background: rgba(185, 185, 185, .1);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);

  &__menu {
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
    }

    &__link {
      padding: 6px 12px;

      &:hover {
        color: var(--color-dark-basic);
      }

      &:not(:last-child) {
        margin-right: 12px;
      }

      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: #A0A0A0;

      &__notifications {
        border: none;
        outline: none;
        width: 54px;
        height: 47px;
        border-radius: 4px;
        background: var(--color-dark-lighten);
        font-weight: bold;
        color: #FFFFFF;
        cursor: pointer;

        &:hover {
          color: #FFFFFF;
        }

        &--has {
          background: var(--color-red-basic);
        }
      }

    }
  }
}



