$background: #C4C4C4;

.Calendar {
  $cls: &;

  background: rgba($background, 0.05);
  width: min-content;
  color: var(--color-dark-basic);

  // Rules for week numbers----------
  &__show-week-numbers {
    .Calendar__days__name-week-days {
      & .Calendar__tile:first-child {
        width: 45px;
      }
    }

    & .Calendar__week {
      &__number {
        width: 45px;
      }
    }
  }

  // below rules are for without numbers calendar
  &__days__name-week-days {
    & .Calendar__tile:first-child {
      width: 0;
    }
  }

  &__week {
    &__number {
      width: 0;
    }
  }

  // ---------------------------------

  &__navi {
    height: 55px;
    width: 315px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;

    &__button {
      height: 100%;
      width: 55px;
      display: flex;
      justify-content: center;
      cursor: pointer;
      padding: 0px 14px;
      box-sizing: border-box;
      &:hover {
        background: rgba(196, 196, 196, 0.4);
      }
    }

    &__month {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      height: 100%;
      cursor: pointer;
    }
  }


  &__days__name-week-days {
    display: flex;

    & .Calendar__tile {
      width: 45px;
    }
  }

  &__week {
    display: flex;

    &__number {

      display: flex;
      font-size: 8px;
      line-height: 130%;
    }

    &__days {
      display: flex;

      & .Calendar__tile {
        width: 45px;
        height: 45px;
      }
    }
  }

  &__tile {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__week__day.Calendar__tile {
    cursor: pointer;
    border-radius: 4px;
    &--hasFreeSlotPrevDay {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &--hasFreeSlotNextDay {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__mode {
    &--day {
      &.Calendar {
        & .Calendar__week__day.Calendar__tile {
          &--today, &:hover {
            &:not(.Calendar__tile--active) {
              background: rgba(18, 131, 235, 0.3);
            }
          }


          &--active {
            color: #FFFFFF;
            background: rgb(18, 131, 235);
            font-weight: bold;
          }

          &--weekend:not(.Calendar__tile--active):not(&.Calendar__tile--today) {
            background: rgba($background, 0.1);
          }
        }
      }
    }

    &--week {
      &#{$cls} {

        & #{$cls}__week {
          &__days {
            border-radius: 4px;

            &:hover {
              background: rgba(18, 131, 235, 0.3);
              & #{$cls}__tile {
                background: transparent;
              }
            }
          }

          &--active {
            color: #FFFFFF !important;
            background: rgb(18, 131, 235);
            font-weight: bold;
          }

        }
      }
    }
  }

  &__tile {
    $tile: &;
    &:not(&--active)#{$tile} {
      &--hasAppointment {
        color: var(--color-blue-basic);
        font-weight: bold;
      }
      &--hasFreeSlot {
        background: var(--color-blue-lighten);
      }
    }
  }
}