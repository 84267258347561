[data-reach-combobox-option]:hover {
  background: hsl(211, 10%, 92%);
}



[data-reach-combobox]:focus-within {
  background: rgba(160, 160, 160, 0.1);
  background-image: linear-gradient(180deg, transparent calc(100% - 3px), #1283EB 0);
}

[data-reach-combobox] {
  border-radius: 4px;

  background: transparent;
  border: none;
  outline: none;
  padding: 13px 13px;
}

[data-reach-combobox-input]:not(:placeholder-shown) {
  color: var(--color-dark-basic);
}

[data-reach-combobox-input] {
  font-size: 16px;
  line-height: 130.9%;
  color: var(--color-grey-basic);
  border: none;
  background: transparent;
  outline: none
}

[data-reach-popover][data-reach-combobox-popover] {
  margin-top: 21px;
  padding: 10px 0;
  background: #FFFFFF;
  border: 1px solid rgba(185, 185, 185, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  z-index: 3020;
}

.SymptomsSelector {
  &__symptom {
    &--hasId {
      font-weight: bold;
    }
  }

  &__container {
    display: flex;
    align-items: baseline;

  }

  &__combobox[data-reach-combobox] {
    position: relative;
    display: inline;
    cursor: pointer;
  }

  &__prefix {
    margin-right: 5px;
    color: #565656;
  }

  &__popover {
    width: 255px;
    max-height: 300px;
    position: absolute;
    left: 0 !important;
    top: 56px;
    background: #FFFFFF;
    border: 1px solid rgba(185, 185, 185, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    z-index: 1;
  }

  &__symptomResult {
    font-size: 16px;
    line-height: 130.9%;
    padding: 6px 19px;
    color: var(--color-dark-basic);
    &__fullName [data-user-value] {
      font-weight: normal;
    }

    &__fullName [data-suggested-value] {
      font-weight: normal;
    }
  }
}
