.AppointmentDateSelector {
  display: flex;

  &__prefix {
    margin-top: 13px;
    color: var(--color-dark-basic);
  }

  &__wrapper {
    margin-left: 15px;
  }

  &__fields {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex-direction: row;

    &__during {
      display: flex;
      flex: 1 100%;

      & [data-reach-combobox]:hover {
        background: var(--color-grey-lighten);
      }

      & [data-reach-combobox] {
        padding: 8px;
        box-sizing: border-box;
        margin-right: 4px;

        & [data-reach-combobox-input] {
          font-weight: bold;
        }
      }

      &__list {
        &__option {
          padding: 0 20px;
        }
      }
    }

    &__date {
      position: relative;

      &__label {
        margin: 0 10px;
        width: 122px;
      }

      &__popover[data-reach-menu-popover] {
        margin-bottom: 20px;
        padding: 0;
      }

      &__calendar {
        background: #FFFFFF;
        border: 1px solid rgba(185, 185, 185, 0.5);
        box-sizing: border-box;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
      }
    }

    &__range {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 130.9%;
      color: #565656;

      &__start, &__end {
        font-size: 16px;
        width: 57px !important;
        align-items: center;
        justify-content: center;
        display: flex;
        font-weight: bold;
      }

      &__start {
        &:hover {
          background: var(--color-grey-lighten);
        }

        &:focus-within {
          background: var(--color-grey-lighten);
          background-image: linear-gradient(180deg, transparent calc(100% - 3px), #1283EB 0);
        }

        & input {
          width: 47px;
          font-size: 16px;
          font-weight: bold;
          border: none;
          outline: none;
          background: transparent;
          color: var(--color-dark-basic);
        }
      }
    }
  }


  &__suggestedTimes {
    margin-left: 17px;

    & [data-reach-menu-button] {
      outline: none;
      border: none;
      background: none;
      padding: 0;
      font-size: 15px;
      line-height: 120%;
      color: var(--color-blue-basic);
    }

    &__button {
      cursor: pointer;
    }

    &__list {
      max-height: 250px;
      overflow-y: scroll;
      min-width: 220px;

      & [data-selected] {
        background: hsl(211, 10%, 92%);
        color: var(--color-dark-basic);

      }

      &__option {
        padding: 0 20px;
        color: var(--color-dark-basic);

        &__type {
          &--call {
            color: var(--color-orange-basic) !important;
          }

          &--video {
            color: var(--color-purple-basic) !important;
          }

          &--physical {
            color: var(--color-blue-basic) !important;
          }
        }
      }
    }
  }
}

[data-reach-menu-list] {
  outline: none;
}

[data-reach-menu-popover] {
  margin-top: 21px;
  padding: 12px;
  min-width: 94px;
  background: #ffffff;
  border: 1px solid rgba(185, 185, 185, 0.5);
  box-sizing: border-box;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  align-items: center;
  z-index: 3020;
}

[data-reach-menu-item] {
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  line-height: 130.9%;
}
