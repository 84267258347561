
.ScheduleEditingDateSelector {
  display: flex;
  &__prefix {
    margin-top: 15px;
    color: var(--color-dark-basic);
  }

  &__wrapper {
    margin-left: 10px;
  }

  &__fields {
    display: flex;
    font-weight: bold;
    margin-bottom: 15px;

    &__during {
      display: flex;
      margin-right: 8px;
      width: max-content;
      &__combobox {
        height: 44px;
        box-sizing: border-box;

        &:hover {
          background: var(--color-grey-lighten);
        }
      }

      & input {
        font-weight: bold;
        width: min-content !important;
        max-width: 80px

      }
    }

    &__range {
      display: flex;
      align-items: center;
      color: var(--color-dark-basic);

      &__start, &__end {
        width: 54px;
        margin-right:4px;
        height: 100%;
        border-radius: 4px;
        &:hover, &:focus-within {
          background: var(--color-grey-lighten);
        }

        & input {
          width: 54px;
          height: 100%;
          background: transparent;
          border: none;
          outline: none;
          font-size: 16px;
          color: var(--color-dark-basic);
          font-weight: bold;

        }
      }

      &__end {
        margin-left: 8px;
      }
    }

    &__date {
      &__label {
        width: max-content;
        margin-left: 8px;
      }
    }
  }

  &__suggestedTimes {
    &__list {
      max-height: 200px;
      overflow-y: scroll;
      & [data-selected] {
        background: hsl(211, 10%, 92%);
        color: var(--color-dark-basic);
      }
    }

    & [data-reach-menu-button] {
      background: none;
      outline: none;
      border: none;
      color: var(--color-blue-basic);
      cursor: pointer;
    }
  }
}