$minHeight: 40px;
$sizeHeader: #{116px + 42px + 31px + 34px}; // This is sizes of page's header and navi

.Schedule {

  &__time {
    border: 1px solid var(--color-red-basic);
    z-index: 0;
    position: absolute;
    width: 100%;
  }

  &__loader {
    position: absolute;
    left: 50%;
    top: calc((100vh - #{$sizeHeader}) / 2.3);
  }

  &__container {
    display: flex;
    position: relative;
  }

  &__hours {
    margin-top: 20px
  }

  &__hour {
    height: $minHeight;
    border-top: 1px solid transparent;
    font-size: 12px;
    line-height: 130%;
    color: var(--color-grey-basic);
    width: 38px;

    &__text {
      margin-top: -8px;
    }
  }

  &__presentation {
    width: 100%;
    position: relative;

    &__background {
      width: 100%;
      margin-top: 29px;

      &__row {
        display: flex;
        position: relative;
        border-top: 1px solid rgba(14, 37, 80, 0.1);
        height: $minHeight;
      }
    }

    &__closeSlots {
      position: absolute;
      width: 100%;
      background: repeating-linear-gradient(-65deg, transparent, transparent 10px, rgba(0, 0, 0, 0.05) 10px, rgba(0, 0, 0, 0.05) 20px);
      &__type {
        height: 100%;
        width: 11px;
        backogrund: #fff;
      }
    }

    &__column {
      position: relative;
      min-width: 213px;
      width: 100%;
      padding-bottom: 70px;

      &__container {
        border-left: 1px solid rgba(14, 37, 80, 0.1);
        position: relative;
      }

      &__title {
        background: #FFFFFF;
        display: flex;
        align-items: center;
        color: var(--color-grey-basic);
        width: 100%;
        height: 29px;
        z-index: 99999999999999;

        & .icon {
          margin-left: 5px;
          cursor: pointer;

          &:hover {
            color: var(--color-orange-basic);
          }
        }
      }


      &__type {
        width: 11px;
        position: absolute;
        top: 29px;
        left: 1px;
        margin-top: 30px;
        z-index: -1;

        &--physical {
          background: var(--color-blue-lighten)
        }

        &--video {
          background: var(--color-purple-lighten)
        }

        &--call {
          background: var(--color-orange-lighten)
        }
      }
      
      &__freeSlot {
        position: absolute;
      }
    }

    &__addParticipant {
      &__icon {
        color: var(--color-blue-basic);
        font-size: 20px;
        position: absolute;
        right: 0;
      }

      &__form {
        max-width: 578px;
        padding: 0 !important;
      }
    }

  }

}

.Schedule-Presentation {
  &__addParticipant__form {
    min-width: 460px;
    max-width: 460px;
    position: relative;
    padding: 0 !important;
  }

  &__presentation {
    &__addParticipant__icon {
      position: absolute;
      top: 0;
      right: 2px;
      color: var(--color-blue-basic);
      cursor: pointer;
    }

    &__columns {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - 30px);
      display: flex;
      overflow: hidden;
      overflow-x: scroll;

      &__loader {
        position: absolute;
        top: calc(50vh - 115px - 65px);
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

}
