.ErrorBoundary {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__message {
    width: 70%;
    text-align: center;
    font-size: 40px;
    line-height: 49px;
    background: linear-gradient(90deg, rgba(251, 155, 42, 1) 0%, rgba(251, 155, 42, 1) 40%, rgba(18, 131, 235, 1) 78%, rgba(180, 79, 202, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    & a {
      -webkit-text-fill-color: var(--color-purple-basic);

      background: transparent;
      color: red;
      text-decoration: underline;
    }
  }

}