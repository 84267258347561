.AppointmentComments {
  display: flex;
  align-items: flex-start;
  &__prefix {
    margin-right: 5px;
    margin-top: 13px;
    color: var(--color-dark-basic);
  }
  &__field {
    max-height: 100px;
    overflow-y: scroll;
    outline: none;
    font-size: 16px;
    line-height: 130.9%;
    &:empty::before {
      content: "Comments";
      color: var(--color-dark-lighten);
    }
    &:not(:focus) {
      font-weight: bold;
    }
  }
}