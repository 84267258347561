.appointmentForm {
  width: 578px;
  position: relative;

  &__controllerType {
    margin: 34px 0 24px;

    & .button:not(:first-child) {
      margin-left: 6px;
    }
  }

  &__fieldset {
    border: none;
    padding: 60px 24px 60px 60px;
    margin-left: 0;

    &__container {
      display: grid;
      grid-template-columns: 381px;
      grid-gap: 30px;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    min-height: 96px;
    max-height: 120px;
    border-top: 1px solid #A0A0A0;

    &__formError {
      margin-left: 25px;

      color: var(--color-red-basic);
      font-size: 15px;
      &:not(:last-child) {
        &::after{
          content: ', '
        }
      }
    }
  }

  &__button--cancel {
    margin-right: 6px;
    margin-left: 8px;
  }

  &__button--create {
    margin-right: 24px;
  }

  &__close {
    position: absolute;
    top: 24px;
    right: 24px;
    font-size: 24px;
    color: var(--color-grey-basic);
    cursor: pointer;

    &:hover {
      color: var(--color-dark-basic);
    }
  }
}

.appointmentForm__shadow-popup {
  z-index: 3001;
}

[data-reach-dialog-overlay] {
  z-index: 3000;
}
