[aria-label='EditTemplateForm'] {
  width: max-content;
  padding: 0;
}

.ScheduleEditingForm {
  &__field {
    &:not(:first-child) {
      margin-top: 34px;
    }
  }

  &__content {
    padding: 32px;
  }

  &__footer {
    min-height: 96px;
    border-top: 1px solid var(--color-dark-lighten);
    box-sizing: border-box;
    padding: 0 32px;

    &__buttons {
      margin-top: 25px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  &__button {
    &--cancel {
      margin-right: 6px;
    }
  }
}