.Menu-Calendar {
  $cls: &;
  &__label {
    border: none;
    outline: none;
    background: none;
    font-size: inherit;
    font-weight: bold;
    color: var(--color-blue-basic);
    cursor: pointer;
    &:hover {
      font-color: inherit;
      opacity: .5;
    }
  }

  &__buttons-change-view {
    display: flex;
    justify-content: center;
    margin-top: 12px;
    & .button {
      color: var(--color-dark-basic);
      &:first-child {
        margin-right: 5px;
      }
    }
  }

  &__container {
    position: absolute;
    z-index: 1200;
    right: 0;
    top: 50px;
    background: #ffffff;
    box-shadow: 0px 0.249053px 2.21381px rgba(0, 0, 0, 0.0169942),
    0px 0.598509px 5.32008px rgba(0, 0, 0, 0.0231048),
    0px 1.12694px 10.0172px rgba(0, 0, 0, 0.0276888),
    0px 2.01027px 17.869px rgba(0, 0, 0, 0.0327272),
    0px 3.75998px 33.4221px rgba(0, 0, 0, 0.0404888),
    0px 9px 80px rgba(0, 0, 0, 0.07);

    & .close {
      align-self: flex-end;
      align-self: flex-end;
      margin: 24px 16px 0 0;
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      font-size: 24px;
      color: var(--color-grey-basic);
      &:hover {
        color: var(--color-dark-basic);
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      margin-top: 40px;
    }
  }

  &__field {
    display: flex;
    align-self: center;

    & img {
      cursor: pointer;
    }

    & #{$cls}__container__item__text {
      margin: 0 8px;
      display: flex;
      align-items: center;

      & img {
        margin-right: 7px;
      }
    }
  }



  &__component {
    margin: 30px 54px 54px;
  }

}

.AppointmentDateSelector {
  &__fields {
    &__date {
      &__popover {
        position: absolute;
        right: 0;
        top: 12px;
      }
    }
  }
}