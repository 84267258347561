.ChatList {
  padding: 0 32px;

  &__chatHeader {
    padding: 0 32px;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid var(--color-dark-lighten);
    margin-bottom: 34px;
  }

  &__chatList {
    color: var(--color-dark-basic);

    &__item {
      display: flex;
      padding: 14px 9px;
      border: 9px;
      cursor: pointer;
      text-decoration: none;
      color: var(--color-dark-basic);

      &:hover {
        background: var(--color-grey-lighten);
      }

      &__person {
        display: flex;
        margin-right: 50px;
        width: 230px;
        min-width: 230px;
        align-items: center;

        &__patientId {
          font-size: 12px;
        }

        &__photo {
          width: 40px;
          min-width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          font-weight: bold;
          color: white;

          &--empty {
            background: var(--color-blue-basic);
          }
        }

        &__info {
          margin-left: 16px;
          display: flex;
          flex-direction: column;
          justify-content: center;

        }
      }

      &__symptoms {
        width: 140px;
        max-width: 140px;
        display: flex;
        align-items: center;
      }

      &__appointment {
        width: 220px;
        max-width: 220px;
        display: flex;
        align-items: center;

        & a {
          text-decoration: none;
          color: var(--color-dark-basic);
        }

        &__when {
          display: flex;
          flex-direction: column;

          &__time {
            font-size: 12px;
            line-height: 130.9%;
          }
        }

        &__type--physical &__when__title {
          color: var(--color-blue-basic);
        }

        &__type--call &__when__title {
          color: var(--color-orange-basic);
        }

        &__type--video &__when__title {
          color: var(--color-purple-basic);
        }
      }

      &__partner {
        width: 180px;
        max-width: 180px;
        min-width: 180px;
      }

      &__previewMessages {
        margin-left: 12px;
        display: flex;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;

        &__text {
          margin-right: 12px;

          &--unread {
            font-weight: bold;
          }
        }

        &__time {
          margin-left: auto;
          font-size: 16px;
          line-height: 130.9%;
          align-self: center;
          color: var(--color-grey-basic);
        }

        &__notification {
          margin-left: auto;
          height: 32px;
          min-width: 32px;
          width: max-content;
          box-sizing: border-box;
          color: #FFFFFF;
          font-weight: bold;
          background: var(--color-red-basic);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
        }
      }
    }
  }

  &__loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}