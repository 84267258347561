.pageChat {
  min-width: 950px;
  &__header {
    height: 116px;
    display: flex;
    align-items: center;
    padding: 0 32px;
    border-bottom: 1px solid var(--color-dark-lighten);

    &__backButton {
      font-size: 32px;
      color: var(--color-grey-basic);
      margin-right: 15px;
      cursor: pointer;
    }

    & .Chat-Menu__container {
      margin-left: auto;
    }
  }


  &__chat {
    &__startDate {
      color: var(--color-grey-basic);
      align-self: center;
      position: relative;
      text-align: center;
      width: 100%;

      & span {
        background: #FFFFFF;
        padding: 0 9px;
      }

      &::before {
        content: '';
        border-bottom: 1px dashed var(--color-grey-basic);;
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        z-index: -2;
        width: 100%;
      }
    }

    &__messagesList {
      padding: 33px 50px 0;
      height: calc(100vh - 270px);
      overflow: scroll;
      display: flex;
      flex-direction: column;
    }

    &__message {
      margin: 16px 0;
      display: flex;

      &--isMyMessage {
        align-self: flex-end;
      }

      &__photo {
        &__chatBot {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: var(--color-blue-basic);
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          font-size: 16px;
        }
      }

      &__text {
        margin-left: 12px;
        padding: 16px 24px;
        background: rgba(18, 131, 235, 0.1);
        border-radius: 0px 20px 20px 20px;
        max-width: 700px;

        &__file {
          border: 1px solid var(--color-blue-basic)
        }

        &__details {
          margin-top: 9px;
          color: var(--color-grey-basic)
        }
      }
    }

    &__person {
      &__photo {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: bold;

        &--empty {
          background: var(--color-green-basic);
        }
      }
    }

    &__sendForm {
      padding: 0 33px;
      width: 100%;
      min-height: 56px;
      border-radius: 4px;
      box-shadow: 0px 0.249053px 2.21381px rgba(0, 0, 0, 0.0169942),
      0px 0.598509px 5.32008px rgba(0, 0, 0, 0.0231048),
      0px 1.12694px 10.0172px rgba(0, 0, 0, 0.0276888),
      0px 2.01027px 17.869px rgba(0, 0, 0, 0.0327272),
      0px 3.75998px 33.4221px rgba(0, 0, 0, 0.0404888),
      0px 9px 80px rgba(18, 131, 235, 0.07);

      &__files {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        border-bottom: 1px solid var(--color-grey-lighten);
        padding: 12px 0;
        & .Attachments__item {
          margin-top: 12px;
          &:not(last-child) {
            margin-right: 15px;
          }
        }
      }

      &__inputContainer {
        display: flex;
        align-items: center;
      }

      & [data-reach-combobox] {
        border-radius: 0;
        background: transparent;
        border: none;
        outline: none;
        padding: 13px 13px;
      }

      &__inputField, & [data-reach-combobox-input] {
        font-size: 16px;
        line-height: 130.9%;
        width: 100%;
      }

      &__sendButton {
        cursor: pointer;
        margin-left: auto;
        background: var(--color-blue-basic);
        height: 56px;
        width: 217px;
        font-size: 16px;
        line-height: 120%;
        color: #FFFFFF;
        font-weight: bold;
        border: none;
        outline: none;

      }
    }
  }

}