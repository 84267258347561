.Loader {
  white-space: nowrap;
  &__dote {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: var(--color-dark-lighten);
    display: inline-block;
    animation: pulsating 1.5s infinite;

    &:nth-child(1) {
      animation-delay: 0;
    }

    &:nth-child(2) {
      animation-delay: 100ms;
    }

    &:nth-child(3) {
      animation-delay: 200ms;
    }
  }

}

@keyframes pulsating {
  0%, 40%, 100% {
    transform: scale(.6, .6);
  }
  10% {
    transform: scale(1.3, 1.3);
  }
}