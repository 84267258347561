[data-reach-combobox-option]:hover {
  background: hsl(211, 10%, 92%);
}


[data-reach-combobox] {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

[data-reach-combobox]:focus-within {
  background: rgba(160, 160, 160, 0.1);
  background-image: linear-gradient(180deg, transparent calc(100% - 3px), #1283EB 0);
}

[data-reach-combobox] {
  border-radius: 4px;

  background: transparent;
  border: none;
  outline: none;
  padding: 13px 13px;

}

[data-reach-combobox-input]:not(:placeholder-shown) {
  color: var(--color-dark-basic);
}

[data-reach-combobox-input] {
  font-size: 16px;
  line-height: 130.9%;
  color: var(--color-grey-basic);
  border: none;
  background: transparent;
  outline: none;
}
[data-reach-popover][data-reach-combobox-popover] {
  margin-top: 21px;
  padding: 10px 0;
  background: #FFFFFF;
  border: 1px solid rgba(185, 185, 185, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  z-index: 3020;
}

.LocationSelector {
  &__container {
    display: flex;
    align-items: center;

    [data-reach-combobox-input]:not([value=""]):not(:focus) {
      font-weight: bold;
    }
  }

  &__prefix {
    margin-right: 5px;
    color: #565656;

  }

  &__clear-input {
    cursor: pointer;
    color: #B9B9B9
  }

  &__locationResult {
    display: flex;
    color: var(--color-dark-basic);
    font-size: 16px;
    line-height: 130.9%;
    font-weight: normal;

    &__photo {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 16px;

      &-empty {
        background: var(--color-blue-basic);
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: bold;
      }
    }

    &__fullName [data-user-value] {
      font-weight: normal;
    }

    &__fullName [data-suggested-value] {
      font-weight: normal;
    }
  }

  &__locationSelected {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    padding: 4px 15px 4px 10px;

    &__remove {
      color: var(--color-dark-basic);
      display: none;
    }

    &:hover {
      background: var(--color-grey-lighten);
      & .locationSelected__remove {
        display: block;
      }
    }
  }
}

