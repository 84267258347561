[data-reach-tab-panel] {
  display: none;
}

[data-reach-tab-list] {
  background: none;
}

[data-reach-tab][data-selected] {
  border: none;
}

[data-reach-tab]:focus {
  outline: none;
}

[data-reach-tab] {
  padding: 10px 23px;
  border-radius: 4px;

  &.appointmentType {

    &:not(:last-child) {
      margin-right: 4px;
    }

    &--physical {
      color: var(--color-blue-basic);

      &:hover {
        background-color: var(--color-blue-lighten);
      }
    }

    &--video {
      color: var(--color-purple-basic);

      &:hover {
        background-color: var(--color-purple-lighten);
      }
    }

    &--call {
      color: var(--color-orange-basic);

      &:hover {
        background-color: var(--color-orange-lighten);
      }
    }
  }
}


[data-selected] {

  &.appointmentType {
    &--physical {
      background-color: var(--color-blue-lighten);
    }

    &--video {
      background-color: var(--color-purple-lighten);
    }

    &--call {
      background-color: var(--color-orange-lighten);
    }
  }
}

