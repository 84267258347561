.button:focus {
  outline: none;
}

.button {
  width: 163px;
  height: 44px;

  border-radius: 4px;
  font-weight: bold;
  font-size: 15px;
  line-height: 120%;
  text-align: center;
  border-width: 0;
  cursor: pointer;

  &--danger {
    background: transparent;
    color: var(--color-red-basic);
    &:hover {
      background: var(--color-red-lighten);
    }
  }

  &--completed {
    background: var(--color-green-basic);
    color: #FFFFFF;
  }

  &--cancel {
    background: transparent;
    color: var(--color-grey-basic);
    &:hover {
      background: rgba(185, 185, 185, 0.3);
    }
  }

  &--primary {
    background: var(--color-blue-basic);
    color: #FFFFFF;
  }

  &--disabled {
    background: rgba(185, 185, 185, 0.3);
    color: #FFFFFF;
    // cursor: auto;
  }

  &--grey {
    background: rgba(160, 160, 160, 0.1);
    color:var(--color-dark-basic);
  }

  &--transparent {
    background: transparent;
    color:var(--color-dark-basic);
    &:hover {
      background: rgba(#565656, 0.1);
    }
    &.button--active {
      background: rgba(#565656, 0.1);
    }
  }

}
