.Chat {
  min-width: 950px;

  &__content {
    flex: 0 1 100%;
    display: flex;
    justify-content: space-between;
    overflow: auto;
  }

  &__appointment {
    min-width: 267px;
    padding: 34px;

    &__create {
      font-weight: bold;
      color: var(--color-blue-basic);
      cursor: pointer;

      &:hover {
        opacity: .5;
      }
    }

    & .Schedule-Appointment {
      position: relative;
    }
  }


  &__appointment__state--canceled &__message__text {
    color: #FFFFFF;
    background: var(--color-red-basic);

    & * {
      color: #FFFFFF;
    }
  }

  &__appointment__state--payed &__message__text {
    color: #FFFFFF;
    background: var(--color-blue-basic);

    & * {
      color: #FFFFFF;
    }
  }

  &__appointment__state--diagnosed &__message__text {
    color: #FFFFFF;
    background: var(--color-orange-basic);

    & * {
      color: #FFFFFF;
    }
  }

  &__appointment__state--checked_in &__message__text {
    color: #FFFFFF;
    background: var(--color-purple-basic);

    & * {
      color: #FFFFFF;
    }
  }

  &__appointment__state--booked &__message__text {
    color: #FFFFFF;
    background: var(--color-grey-basic);

    & * {
      color: #FFFFFF;
    }
  }

  &__header {
    height: 116px;
    display: flex;
    align-items: center;
    padding: 0 32px;
    border-bottom: 1px solid var(--color-dark-lighten);

    &__backButton {
      font-size: 32px;
      color: var(--color-grey-basic);
      margin-right: 15px;
    }

    &__menu {
      margin-left: auto;
    }
  }


  &__startDate {
    color: var(--color-grey-basic);
    align-self: center;
    position: relative;
    text-align: center;
    width: 100%;

    & span {
      background: #FFFFFF;
      padding: 0 9px;
    }

    &::before {
      content: '';
      border-bottom: 1px dashed var(--color-grey-basic);;
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      z-index: -2;
      width: 100%;
    }
  }

  &__vertical-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 182px);
    overflow: hidden;
  }

  &__messagesList {
    padding: 33px 50px 0;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__message {
    margin: 16px 0;
    display: flex;
    height: max-content;
    min-height: max-content;

    &--isMyMessage {
      align-self: flex-end;

    }

    &--isMyMessage & {
      &__text {
        border-radius: 20px 0px 20px 20px;
        margin-left: 0;
        margin-right: 12px;
      }
    }

    &__photo {
      &__chatBot {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: var(--color-blue-basic);
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 16px;
      }
    }

    &__text {
      margin-left: 12px;
      padding: 16px 24px;
      background: rgba(18, 131, 235, 0.1);
      border-radius: 0px 20px 20px 20px;
      max-width: 700px;

      &__details {
        margin-top: 9px;
        color: var(--color-grey-basic)
      }

      &__file {
        border: 1px solid rgba(18, 131, 235, 0.3);
        display: flex;
        align-items: center;
        padding: 8px 16px;
        margin-top: 7px;
        border-radius: 4px;
        color: var(--color-blue-basic);
        font-weight: bold;
        cursor: pointer;
      }
    }
  }

  &__person {
    &__photo {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-weight: bold;

      &--empty {
        background: var(--color-green-basic);
      }
    }
  }

  &__sendForm {
    flex: 1 1 auto;
    padding: 0 33px;
    min-height: 56px;
    border-radius: 4px;
    box-shadow: 0px 0.249053px 2.21381px rgba(0, 0, 0, 0.0169942),
    0px 0.598509px 5.32008px rgba(0, 0, 0, 0.0231048),
    0px 1.12694px 10.0172px rgba(0, 0, 0, 0.0276888),
    0px 2.01027px 17.869px rgba(0, 0, 0, 0.0327272),
    0px 3.75998px 33.4221px rgba(0, 0, 0, 0.0404888),
    0px 9px 80px rgba(18, 131, 235, 0.07);

    &__files {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      border-bottom: 1px solid var(--color-grey-lighten);
      padding: 12px 0;

      & .Attachments__item {
        margin-top: 12px;

        &:not(last-child) {
          margin-right: 15px;
        }
      }
    }

    &__flexContainer {
      display: flex;
      align-items: center;
    }

    & [data-reach-combobox] {
      border-radius: 0;
      background: transparent;
      border: none;
      outline: none;
      padding: 13px 13px;
    }

    &__inputField, & [data-reach-combobox-input] {
      font-size: 16px;
      line-height: 130.9%;
      width: 100%;
    }

    &__sendButton {
      cursor: pointer;
      margin-left: auto;
      background: var(--color-blue-basic);
      height: 56px;
      width: 217px;
      font-size: 16px;
      line-height: 120%;
      color: #FFFFFF;
      font-weight: bold;
      border: none;
      outline: none;

    }
  }

  &__attachment {
    &__upload__button {
      outline: none;
    }

    &__container {
      display: flex;
      align-items: center;
    }

    &__label {
      color: var(--color-blue-basic);
      display: flex;
      cursor: pointer;
      outline: none;

      &:hover {
        opacity: .5;
      }
    }

    &__item {
      padding: 8px 16px;
      border-radius: 4px;
      border: 1px solid rgba(18, 131, 235, 0.3);
      color: var(--color-blue-basic);
      font-weight: bold;

      &__size {
        font-weight: bold;
        color: var(--color-grey-basic);
      }

      & .close {
        color: var(--color-grey-basic);
        cursor: pointer;
        margin-left: 20px;

        &:hover {
          color: var(--color-dark-basic);
        }
      }
    }
  }

}