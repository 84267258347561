.Notifications {
  position: fixed;
  right: 20px;
  top: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__isGlobal {
    top: 9px;
    left: 50%;
    width: 60%;
    transform: translateX(-50%);
  }
  .Notifications__item-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .Notifications__item-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .Notifications__item-exit {
    opacity: 1;
  }
  .Notifications__item-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }


  &__item {
    background: var(--color-dark-basic);
    color: #FFFFFF;
    padding: 13px 24px;
    border-radius: 4px;
    user-select: none;
    display: flex;


    &__buttons {
      margin-left: 32px;
      cursor: pointer;
      color: #49A5FB;
      white-space: nowrap;
      & a {
        text-decoration: none;
        color: #49A5FB;
      }
    }

    & .icon {
      margin-left: 32px;
      color: var(--color-dark-lighten);
    }

    &:not(:first-child) {
      margin-top: 4px;
    }
  }
}