.DoctorNotes {
  background: #FFFFFF;
  height: 100%;
  padding: 43px 37px;
  margin-top: 58px;
  border: 2px solid var(--color-blue-basic);
  box-shadow: 0px 9px 80px rgba(18, 131, 235, 0.07), 0px 3.75998px 33.4221px rgba(0, 0, 0, 0.0404888), 0px 2.01027px 17.869px rgba(0, 0, 0, 0.0327272), 0px 1.12694px 10.0172px rgba(0, 0, 0, 0.0276888), 0px 0.598509px 5.32008px rgba(0, 0, 0, 0.0231048), 0px 0.249053px 2.21381px rgba(0, 0, 0, 0.0169942);
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__item {
    margin-top: 27px;
    color: var(--color-grey-basic);

    &--symptoms {
      & .SymptomsSelector__prefix {
        display: none;
      }
    }

    &--hasTerm, &:focus-within {
      color: var(--color-dark-basic);
    }

    &__title {
      font-weight: bold;
      font-size: 16px;
      line-height: 130.9%;
      user-select: none;
      margin-bottom: 4px;
    }

    & [data-textarea-notes] {
      width: 100%;
      box-sizing: border-box;
      display: block;
      max-width: 100%;
      font-smoothing: subpixel-antialiased;
    }
  }

  &__send {
    margin-top: 20px;
    margin-left: auto;
  }

}
