.badge {
  position: relative;

  .badge-count {
    height: 20px;
    font-weight: normal;
    font-size: 12px;
    text-align: center;
    color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 0 1px #ffffff;

    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    transform-origin: 100% 0;
    padding: 0 8px;

    .badge-count__text {
      line-height: 20px;
    }
  }
}
