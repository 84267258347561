.NewVisit-Toggler {
  &__container {
    cursor: pointer;
    color: var(--color-dark-basic);
    display: flex;
    align-items: center;
    height: 44px;
  }

  &__icon {
    margin-right: 15px;
  }

  &__loader {

    width: 97px;
    height: 44px;
  }

  &__button {
    width: 97px;
    height: 100%;
  }
}
