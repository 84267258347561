.TemplateEditing-Column {
  width: 100%;
  height: 100%;
  min-width: 210px;

  &__button-addSlot {
    font-weight: bold;
    display: none;
  }

  &__title {
    white-space: nowrap;
    font-size: 16px;
    line-height: 130.9%;
    font-weight: bold;
    color: var(--color-dark-basic);
    height: 34px;
    display: flex;

    &__time {
      font-weight: normal;
      color: var(--color-grey-basic)
    }
  }

  &__content {
    border-right: 1px solid rgba(14, 37, 80, 0.1);
    width: 100%;
    height: 100%;
    position: relative;
  }
}