.pageLogin {
  &__message {
    font-size: 40px;
    line-height: 49px;
    background: linear-gradient(90deg, rgba(251, 155, 42, 1) 0%, rgba(251, 155, 42, 1) 40%, rgba(18, 131, 235, 1) 78%, rgba(180, 79, 202, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__content {
    text-align: center;
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.logo {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
}

