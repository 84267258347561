.ParticipantSelector {
  &__container {
    display: flex;
    align-items: center;

    & [data-reach-combobox] {
      position: relative;
    }
  }

  &__popover {
    position: absolute;
    top: 56px;
    left: 0;
    background: #FFFFFF;
    border: 1px solid rgba(185, 185, 185, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    z-index: 2
  }

  &__selected {
    margin-left: 18px;
  }

  &__prefix {
    margin-right: 5px;
    color: var(--color-dark-basic);
  }

  &__clear-input {
    cursor: pointer;
    color: #B9B9B9
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin: 14px 20px 6px 19px;
  }

  &__button {
    background: none;
    outline: none;
    border: none;
    font-weight: normal;
    font-size: 12px;
    line-height: 120%;
    color: var(--color-blue-basic);
  }
}

.participant {
  &__container {
    display: flex;
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    cursor: pointer;
    padding: 4px 13px 4px 10px;

    &:hover {
      background: var(--color-grey-lighten);
    }

    &:hover &__remove {
      display: block;
    }

    &__remove {
      color: var(--color-grey-basic);
      display: none;
    }

    & i {
      color: #B9B9B9;
    }

    &__info {
      display: flex;
      flex-direction: row;
      align-items: center;
      line-height: 130.9%;
      color: var(--color-dark-basic);

      &__fullName {
        font-size: 16px;
      }

      &__speciality {
        font-size: 12px;
      }

      &__photo {
        &--container {
          margin-right: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          &__overlay {
            position: absolute;
            border-radius: 50%;
            background: linear-gradient(0deg, rgba(45, 175, 0, 0.75), rgba(45, 175, 0, 0.75));
            width: 100%;
            height: 100%
          }

          & .icon-check {
            position: absolute;
            line-height: 120%;
            color: white;
            font-size: 16px;
          }
        }

        &-empty {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: var(--color-blue-basic);
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          color: white;
        }

      }
    }
  }
}

.group__button {
  display: flex;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  align-items: center;
  width: 100%;
  color: var(--color-dark-basic);

  font-size: 16px;
  line-height: 130.9%;

  padding: 6px 21px 6px 19.75px;

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__name {
      text-transform: lowercase;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  &__photo {
    &--empty {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: var(--color-blue-basic);
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      color: white;
      margin-right: 11px;
    }
  }

  &:hover {
    background: var(--color-grey-lighten);
  }
}

.icon-open-group {
  margin-left: auto;
}


[data-reach-accordion-item][data-state="open"] [data-reach-accordion-panel] {
  border-top-color: rgba(160, 160, 160, 0.3);
}

[data-reach-accordion-item][data-state="open"]:not(:last-child) [data-reach-accordion-panel] {
  border-bottom-color: rgba(160, 160, 160, 0.3);
}

[data-reach-accordion-item][data-state="collapsed"] [data-reach-accordion-button] .icon-open-group {
  transform: rotate(180deg);
}

[data-reach-accordion-item][data-disabled] {
}

[data-reach-accordion-item] {
  & [data-reach-combobox-option] {
    padding: 6px 21px 6px 19.75px;

    &:hover {
      background: var(--color-grey-lighten);
    }
  }
}

[data-reach-popover][data-reach-combobox-popover] {
  margin-top: 21px;
  padding: 10px 0;
  background: #FFFFFF;
  border: 1px solid rgba(185, 185, 185, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  z-index: 3020;
}
