.ScheduleEditing-Menu {
  &__container {
    position: relative;
    display: flex;

    &__item {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-right: 35px;
      }

      & .icon {
        color: var(--color-grey-basic);
        cursor: pointer;
        margin-right: 7px;
      }

      &__text {
        font-weight: bold;
        color: #1383EB;
        cursor: pointer;

        &:hover {
          opacity: .5;
        }
      }

    }
  }
}