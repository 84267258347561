.AppointmentPage-Member {
  display: flex;
  align-items: center;
  color: var(--color-dark-basic);
  &__state {
    display: flex;
    align-items: center;
  }

  &__notification {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
    margin-left: 37px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    background-image: url("./images/notification.svg");

    &__count {
      position: absolute;
      z-index: 2;
      font-weight: bold;
      font-size: 16px;
      line-height: 130.9%;
      color: var(--color-grey-basic);
    }
  }

  &__photo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;

    &--empty {
      background: var(--color-blue-basic);
    }
  }

  &__info {
    margin-left:16px;
    font-size: 16px;
    line-height: 130.9%;
    &__bankID {
      font-size: 12px;
    }
  }
}