[data-reach-slider-handle] {
  z-index: 0;
  outline: none;
}

[data-reach-slider] {
  outline: none;
}

.SliderZoom {
  &__container {
    width: 200px;
    display: flex;
    cursor: pointer;

    & img:first-child {
      margin-right: 10px;
    }

    & img:last-child {
      margin-left: 15px;
    }

    & [data-reach-slider-track-highlight] {
      background: transparent;
    }

    & [role='slider'] {
      background: #1283EB;
      border-color: #1283EB;
    }
  }
}