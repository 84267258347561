.TemplateEditing {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 54px 54px 0 33px;

    &__title {
      width: 100%;
      margin-right: 45px;

      & input {
        width: 100%;
        font-size: 40px;
        line-height: 49px;
        font-weight: bold;
      }
    }
  }

  &__content {
    padding: 0 33px 86px;
    overflow-x: scroll;
  }

  &__footer {
    width: 100%;
    height: 76px;
    background: #FFFFFF;
    position: fixed;
    bottom: 0;
    border-top: 1px solid var(--color-grey-lighten);
    padding: 16px 54px;
    box-sizing: border-box;

    &__buttons {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      width: 100%;

      &__button {
        &:not(:last-child) {
          margin-right: 8px;
        }

        &__text {
          color: var(--color-blue-basic);
          font-weight: bold;
          margin-left: 30px;
          cursor: pointer;
        }

        &--physical {
          &:not(.button--disabled) {
            background: var(--color-blue-lighten);
            color: var(--color-blue-basic);
            font-weight: normal;
          }
        }

        &--call {
          &:not(.button--disabled) {
            background: var(--color-orange-lighten);
            color: var(--color-orange-basic);
            font-weight: normal;
          }
        }

        &--video {
          &:not(.button--disabled) {
            background: var(--color-purple-lighten);
            color: var(--color-purple-basic);
            font-weight: normal;
          }
        }

        &--clear {
          margin-left: 30px;
          color: var(--color-blue-basic);
          font-weight: bold;
          cursor: pointer;
          & .icon {
            color: var(--color-grey-basic);
          }
        }

        &--discard {
          margin-left: auto;
          font-weight: bold;
          color: var(--color-blue-basic);
          cursor: pointer;
        }

        &--save {
          //margin-left: 40px;
          margin-left: auto;
        }
      }
    }
  }

  &__schedule {
    width: 100%;
    position: relative;

    &__background {
      min-width: calc(971px - 84px);

      width: calc(100vw - 84px);
      margin-top: 9px;
      margin-left: 38px;
      position: absolute;
      top: 0;
      left: 0;

      &__row {
        display: flex;
        position: relative;
        border-top: 1px solid rgba(14, 37, 80, 0.1);
        height: 40px;
      }
    }

    &__hours {
      margin-top: 24px;

      &__hour {
        height: 40px;
        border-top: 1px solid transparent;
        font-size: 12px;
        line-height: 130%;
        color: var(--color-grey-basic);
        width: 38px;

        &__text {
          margin-top: -8px;
        }
      }
    }

    &__columns {
      position: absolute;
      top: 0;
      left: 0;
      margin-left: 38px;
      margin-top: -25px;
      display: flex;
      min-width: calc(971px - 84px);
      width: calc(100vw - 84px);
      overflow-x: scroll;
      overflow-y: hidden;

      &__column {
        width: 100%;
        height: 100%;
        min-width: 210px;

        &__button-addSlot {
          font-weight: bold;
          display: none;
        }

        &__title {
          white-space: nowrap;
          font-size: 16px;
          line-height: 130.9%;
          font-weight: bold;
          color: var(--color-dark-basic);
          height: 34px;
          display: flex;

          &__time {
            font-weight: normal;
            color: var(--color-grey-basic)
          }
        }

        &__content {
          border-right: 1px solid rgba(14, 37, 80, 0.1);
          width: 100%;
          height: 100%;
          position: relative;

        }
      }
    }
  }
}