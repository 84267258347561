.pageProfile{
  display: flex;
  &__photo {
    cursor: pointer;
    position: relative;
    margin-top: 57px;
    margin-right: 57px;
    width: 450px;
    &__image {
      width: 100%;
    }
    &__icon {
      font-size: 24rem;
      color: #565656;
    }
    &__change {
      position: absolute;
      bottom: 4px;
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      display: none;
      &__button {
        border: none;
        outline: none;
        cursor: pointer;
        background: rgba(86, 86, 86, 0.7);
        mix-blend-mode: multiply;

        width: 100%;
        height: 50px;
      }
      &__text {
        position: absolute;
        font-size: 16px;
        line-height: 120%;
        color: #FFFFFF;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &:hover &__change {
      display: flex;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    &__item {
      &:not(:first-child) {
        margin-top: 40px;
      }
      display: flex;
      flex-direction: column;
      &__title {
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.01em;
        color: #A0A0A0;
      }
      &__value {
        font-size: 24px;
        line-height: 29px;
        letter-spacing: -0.01em;
        color: #565656;
      }
    }
  }
}