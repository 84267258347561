.appointmentTitle {
  &__container {
    color: var(--color-dark-basic);
    display: flex;
    align-items: center;
  }
  &__prefix {
    margin-right: 5px;
  }
  &__text {
    margin-left: 13px;
    font-weight: bold;
  }
  &__input {
    &:not(:focus):not(:placeholder-shown) {
      font-weight: bold;
    }
  }
  &__clear-input {
    cursor: pointer;
    color: #B9B9B9
  }
}