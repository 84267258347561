.Schedule-Editing-Slot {
  margin: 0 9px;
  box-sizing: border-box;
  position: absolute;
  width: calc(100% - 18px);
  border-width: .5px;
  left: 0;
  right: 0;
  cursor: pointer;
  z-index: 1000;

  &--isLocked {
    cursor: auto;
  }

  &__time {
    position: absolute;
    top: 15px;
    font-size: 12px;
    line-height: 120%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
  }

  &__container {
    width: 100%;
  }

  $colorsType: (
          'call': 'orange',
          'video': 'purple',
          'physical': 'blue',
  );

  $slot: &;

  @each $type, $color in $colorsType {
    &--#{$type} &__container {
      background: var(--color-#{$color}-basic);
      border-color: var(--color-#{$color}-basic);
      opacity: .2;
    }
    &--#{$type} &__time {
      color: var(--color-#{$color}-basic);
    }

    &#{$slot}--isLocked#{$slot}--#{$type} &__background {
      height: 100%;
      width: 100%;
      position: absolute;
      //background-image: linear-gradient(122deg, rgba(14, 37, 80, 0.1) 11.11%, transparent 11.11%, transparent 50%, rgba(14, 37, 80, 0.1) 50%, rgba(14, 37, 80, 0.1) 61.11%, transparent 61.11%, transparent 100%);
      background-image: linear-gradient(130deg, transparent 33.33%, rgba(14, 37, 80, 0.1) 33.33%, rgba(14, 37, 80, 0.1) 50%, transparent 50%, transparent 83.33%, rgba(14, 37, 80, 0.1) 83.33%, rgba(14, 37, 80, 0.1) 100%);
      background-size: 7.83px 9.33px;
    }
  }


}