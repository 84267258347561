[aria-label='Templates'] {
  padding: 0;
  min-width: 715px;
  width: max-content;
}

.Schedule-Editing-Templates {
  position: relative;

  &__close {
    position: absolute;
    right: 24px;
    top: 16px;
    font-size: 24px;
    color: var(--color-dark-lighten);
    cursor: pointer;

    &:hover {
      color: var(--color-dark-basic);
    }
  }

  &__back {
    color: #B9B9B9;
    top: 24px;
    left: 44px;
    position: absolute;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      color: var(--color-dark-basic);
    }

    & .icon {
      font-size: 24px;
      margin-right: 8px;
    }
  }

  &__content {
    padding: 60px;
  }

  &__footer {
    border-top: 1px solid var(--color-dark-lighten);
    padding: 32px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &__button {
      &:not(:first-child) {
        margin-left: 6px;
      }
    }
  }

  &__template {
    margin: 24px 0;
    display: flex;
    align-items: center;
    color: var(--color-dark-basic);
    font-weight: bold;
    cursor: pointer;

    &__wrapper:hover &__buttons {
      display: flex;
    }

    &__wrapper {
      display: flex;
    }

    &__buttons {
      margin-left: 82px;
      color: var(--color-grey-basic);;
      display: none;

      &__button {
        height: 40px;
        min-width: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px solid var(--color-dark-lighten);
        cursor: pointer;
        margin-top: 28px;
        text-decoration: none;

        &:not(:last-child) {
          margin-right: 3px;
        }

        &--delete {
          color: var(--color-red-basic);

          &:hover {
            border-color: var(--color-red-basic);
          }
        }

        &--edit {
          color: var(--color-blue-basic);

          & .icon {
            margin-right: -3px;
          }

          &:hover {
            border-color: var(--color-blue-basic);
          }
        }

      }
    }

    &__icon {
      align-self: flex-start;
      width: 40px;
      height: 40px;
      min-width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--color-blue-basic);
      font-weight: bold;
      color: #FFFFFF;
      border-radius: 50%;
      margin-right: 16px;
      text-transform: uppercase;

      &--isSelected {
        background: var(--color-green-basic);
      }
    }

    &__preview {
      display: flex;
      font-weight: normal;

      &__day {
        width: 53px;
        height: 100%;
        display: flex;
        flex-direction: column;

        &:not(:last-child) &__content {
          border-right: none;
        }

        &__title {
          font-size: 12px;
          line-height: 130.9%;
          text-transform: capitalize;
          white-space: nowrap;
          color: var(--color-grey-basic);
        }

        &__content {
          height: 125px;
          //background: var(--color-grey-lighten);
          border: 1px solid var(--color-dark-lighten);
          width: 100%;
          position: relative;
          padding: 4px;
          box-sizing: border-box;
          overflow: hidden;

          &__slot {
            position: absolute;
            width: calc(100% - 8px);

            &--video {
              background: var(--color-purple-basic);
              opacity: .2;
            }

            &--physical {
              background: var(--color-blue-basic);
              opacity: .2;
            }

            &--call {
              background: var(--color-orange-basic);
              opacity: .2;
            }
          }
        }
      }

    }

    $template: &;

    &#{$template}--create {
      width: max-content;

      #{$template} {
        &__icon {
          background: var(--color-purple-basic);
        }

        &__preview {
          margin-top: 7px;
          height: 125px;
          border: 1px solid var(--color-dark-lighten);
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 40px;
          color: var(--color-dark-lighten);
        }
      }

      &:hover #{$template}__preview {
        color: var(--color-dark-basic)
      }
    }
  }

  &__newTemplate {
    &__field {
      display: flex;
      align-items: center;
      color: var(--color-dark-basic);
    }
  }

  &__applyTemplate {
    &__weeks {
      &__week {
        display: flex;
        color: var(--color-dark-basic);
        margin: 12px 0;
        cursor: pointer;

        &--isSelected &__icon {
          background: var(--color-green-basic);
        }

        &__icon {
          height: 40px;
          width: 40px;
          min-width: 40px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          color: #FFFFFF;
          background: var(--color-blue-basic);
          margin-right: 16px;
        }

        &__info {
          display: flex;
          align-items: center;

        }
      }
    }
  }
}