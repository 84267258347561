[data-reach-popover] {
  width: max-content;
}

.appointmentStateButton__dropdown {

  & [data-reach-menu-item] {
    padding: 6px 17px;
    box-sizing: border-box;
    cursor: pointer;
    color: var(--color-dark-basic);

    &:hover {
      color: var(--color-dark-basic);
      background: var(--color-grey-lighten);
    }
  }
}

.appointmentStateButton {
  & .icon {
    color: #fff !important;
  }

  &__history {
    padding: 6px 20px;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    line-height: 130.9%;
    font-weight: bold;
    color: var(--color-grey-basic);
  }

  &__menuButton {
    outline: none;
    height: 32px;
    border-radius: 24px;
    border: none;
    color: white;
    font-size: 15px;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    padding: 0 19px;

    &__state {
      &--booked {
        background: var(--color-grey-basic);
      }

      &--checked {
        background: var(--color-purple-basic);
      }

      &--payed {
        background: var(--color-blue-basic);
      }

      &--diagnosed {
        background: var(--color-orange-basic);
      }

      &--completed {
        background: var(--color-green-basic);
      }
    }

    & span {
      color: white;
    }

    & {
      transition: padding-top .2s easy-in-out;
    }

    & .appointmentStateButton__definition {
      color: #FFFFFF;
    }
  }

  &__isCompact--true {
    width: 32px;
    margin-left: 6px;
    & .icon {
      margin-left: 3px;
      margin-top: 1px;
    }
    .appointmentStateButton__menuButton {
      width: 32px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .arrow {
      display: none;
    }
  }

  &__isCompact--true &__definition {
    display: none;
  }
}
