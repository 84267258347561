[data-chat-list-menu] {
  border: none !important;

  & [data-reach-menu-item] {
    color: var(--color-dark-basic);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    line-height: 130.9%;

    &[data-selected] {
      color: var(--color-dark-basic);
      background: var(--color-grey-lighten);
    }
  }
}
.pageAppointment__header {
  display: flex;
  align-items: center;
  padding: 0 32px 12px 33px;
}
.pageAppointment__header__backButton {
  font-size: 32px;
  color: var(--color-grey-basic);
  margin-right: 15px;
}

.Chat-Menu {
  &__container {
    position: relative;
    display: flex;

    &__item {


      &:not(:first-child) {
        margin-left: 35px;
      }

      display: flex;
      align-items: center;

      &__button--ready {
        display: flex;
        flex-wrap: nowrap;
        width: 373px;
        justify-content: center;
        align-items: center;
        padding-right: 20px;
        padding-left: 20px;
      }

      &__text {
        background: none;
        border: none;
        outline: none;
        font-weight: bold;
        color: #1383EB;
        cursor: pointer;
        font-size: 20px;
        line-height: 120%;

        & .icon {
          color: var(--color-grey-basic);
        }

        &:hover {
          opacity: .5;
        }

      }

      & [data-reach-combobox] {
        background: var(--color-grey-lighten);
        padding: 12px 14px;
        width: 381px;
        color: var(--color-grey-basic);

        & [data-reach-combobox-input] {
          width: 100%;
          font-size: 15px;
          line-height: 130.9%;
          text-overflow: ellipsis;
        }
      }

    }
  }
}