:root {
  /* Base font size in pixels. This would result in 22px on desktop */
  --base-font-size: 14;

  /* How large the line height is as a multiple of font size */
  --base-line-height: 1.5;

  --leading: calc(var(--base-line-height) * 1rem);

  --base-font-family: Montseratt Roboto serif;

  /* Rate of growth for headings
     I actually like this to be slightly smaller then the leading. Makes for tight headings.
  */
  --scale: 1.414;

  --color-grey-basic: #A0A0A0;
  --color-grey-lighten: rgba(160, 160, 160, 0.1);
  --color-dark-basic: #565656;
  --color-dark-lighten: #DCDCDC;

  --color-orange-basic: #FB9B2A;
  --color-orange-lighten: #fff5ea;

  --color-purple-basic: #B44FCA;
  --color-purple-lighten: #F7EDFA;

  --color-blue-basic: #1283eb;
  --color-blue-lighten: #E7F3FD;

  --color-green-basic: #2DAF00;
  --color-green-lighten: #E6F5E0;

  --color-red-basic: #EB5312;
  --color-red-lighten: #FDEAE3;

  --height-to-top: 109px;
}

body {
  margin: 0;
  height: 100%;
  &, * {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2 {
  color: var(--color-dark-basic)
}