$cls: Schedule-Appointment;

// We have a few kind of view style of appointment:
// - compact (when there are a few columns in schedule)
// - active (after click on appointment)

.#{$cls} {
  user-select: none;
  position: absolute;
  margin-left: 11px;
  padding-left: 18px;
  padding-right: 12px;
  right: 0;
  left: 0;
  max-width: 938px;
  box-sizing: border-box;
  border-radius: 4px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  & * {
    text-decoration: none;
  }

  &__isCompact--true {
    min-width: 202px;
  }

  &__isCanceled--true {
    border-color: var(--color-red-basic) !important;
    background: var(--color-red-lighten) !important;

    & * {
      color: var(--color-red-basic) !important;
    }
  }

  // In left part of appointments
  &__preview {
    display: flex;
    color: var(--color-dark-basic);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &__title {
      font-weight: bold;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;

      &--info {
        font-weight: normal
      }
    }
  }

  &__detail {
    display: grid;
    width: 100%;
    grid-template-columns: 100%;
    color: var(--color-dark-basic);

    &__icon {
      min-width: 20px;
    }

    &__title {
      font-weight: bold;
      word-break: break-word;
      display: flex;

      &--info {
        font-weight: bold;
        margin-left: 23px;
      }
    }

    &__time {
      font-weight: bold;
      margin-left: 23px;
      margin-top: 6px;
    }

    &__participants, &__symptoms, &__preparations, &__room {
      display: flex;
      margin-top: 9px;
    }

    &__symptoms {
      & .icon {
        margin-right: 8px;
      }
    }

    &__participants {
      & .icon {
        margin-right: 8px;
      }
    }

    &__preparations {
      & .icon {
        margin-right: 7px;
      }
    }

    &__room {
      & .icon {
        margin-right: 11px;
      }
    }
  }

  // In right part of appointments
  &__extra {
    &__button {

    }
  }

  &__state--active {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);

    padding: 18px;
    z-index: 20;

    &.#{$cls} {
      flex-direction: column;

      & .#{$cls} {
        &__button {
          margin-top: 6px;
          align-self: flex-end;
          //top: 50%;
          //-webkit-transform: translateY(-50%);
          //transform: translateY(-50%);
          //position: absolute;
        }
      }
    }
  }

  &__type {
    &--administrative {
      background: rgba(232, 232, 232, 1);
      border: 1px solid var(--color-grey-basic);
    }

    &--complete {
      background: #ffffff;
      border: 1px solid rgba(175, 175, 175, 0.8);
    }

    &--physical {
      & span, & .icon {
        color: var(--color-blue-basic);
      }

      background: var(--color-blue-lighten);
      border: 1px solid #6FA6DD
    }

    &--call {
      & span, & .icon {
        color: #EA8713;
      }

      background: var(--color-orange-lighten);
      border: 1px solid #BB884C;
    }

    &--video {
      & span, & .icon {
        color: var(--color-purple-basic);
      }

      background: #FDF4FF;
      border: 1px solid #D192DF;
    }
  }
}

