
.EditTemplates {
  min-width: 971px;

  &__header {
    &__title {
      margin-top: 0;
      margin-right: auto !important;
    }

    &__backButton {
      font-size: 32px;
      color: var(--color-dark-lighten);
      margin-right: 20px;
      cursor: pointer;

      &:hover {
        color: var(--color-dark-basic);
      }
    }
  }

  &__header {
    padding: 0 54px 0 33px;
    height: 115px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 66px;

    &__title {
      margin: 0;
    }
  }

  &__content {
    padding: 0 33px 86px;
  }


  &__footer {
    width: 100%;
    height: 76px;
    background: #FFFFFF;
    position: fixed;
    bottom: 0;
    border-top: 1px solid var(--color-grey-lighten);
    padding: 16px 54px;
    box-sizing: border-box;
    z-index: 1001;

    &__slots {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;

      &__button {
        &:not(:last-child) {
          margin-right: 8px;
        }

        &__text {
          color: var(--color-blue-basic);
          font-weight: bold;
          margin-left: 30px;
          cursor: pointer;
        }

        &--physical {
          &:not(.button--disabled) {
            background: var(--color-blue-lighten);
            color: var(--color-blue-basic);
            font-weight: normal;
          }
        }

        &--call {
          &:not(.button--disabled) {
            background: var(--color-orange-lighten);
            color: var(--color-orange-basic);
            font-weight: normal;
          }
        }

        &--video {
          &:not(.button--disabled) {
            background: var(--color-purple-lighten);
            color: var(--color-purple-basic);
            font-weight: normal;
          }
        }
      }
    }
  }

  &__schedule {
    width: 100%;
    position: relative;

    &__background {
      min-width: calc(971px - 84px);

      width: calc(100vw - 84px);
      margin-top: 9px;
      margin-left: 38px;
      position: absolute;
      top: 0;
      left: 0;

      &__row {
        display: flex;
        position: relative;
        border-top: 1px solid rgba(14, 37, 80, 0.1);
        height: 40px;
      }
    }

    &__hours {
      margin-top: 24px;

      &__hour {
        height: 40px;
        border-top: 1px solid transparent;
        font-size: 12px;
        line-height: 130%;
        color: var(--color-grey-basic);
        width: 38px;

        &__text {
          margin-top: -8px;
        }
      }
    }

    &__columns {
      position: absolute;
      top: 0;
      left: 0;
      margin-left: 38px;
      margin-top: -25px;
      display: flex;
      min-width: calc(971px - 84px);
      width: calc(100vw - 84px);
      overflow-x: scroll;
      overflow-y: hidden;


      .Loader {
        position: fixed;
        top: 50vh;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

