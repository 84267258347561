.PhotoIcon {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  &__empty {
    background: var(--color-blue-basic);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #FFFFFF;
    &--isDoctor {
      background: var(--color-green-basic);
    }
  }
}