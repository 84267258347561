.FreeSlot {
  $cls: &;

  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: content-box;
  padding-left: 11px;
  box-sizing: border-box;

  &--editing {
    z-index: 1;
    position: relative;
  }

  &__resizableBox {
    position: absolute;
    right: 0;
    left: 11px;
    max-width: 400px;

  }

  &__button {
    min-height: 40px;
    width: 100%;
    height: 100%;
    display: block;


    &--editing {
      background: transparent;
      display: flex;
      border: dashed 2px;

      #{$cls}--video & {
        border-color: var(--color-purple-basic);
        background: var(--color-purple-lighten);
        color: var(--color-purple-basic);
      }

      #{$cls}--call & {
        border-color: var(--color-orange-basic);
        background: var(--color-orange-lighten);
        color: var(--color-orange-basic);

      }

      #{$cls}--physical & {
        border-color: var(--color-blue-basic);
        background: var(--color-blue-lighten);
        color: var(--color-blue-basic)

      }


    }
  }

  &__button__output {
    &, & span {
      cursor: auto;
      font-weight: normal;

      #{$cls}--video & {
        color: var(--color-purple-basic) !important;
      }

      #{$cls}--call & {
        color: var(--color-orange-basic) !important;

      }

      #{$cls}--physical & {
        color: var(--color-blue-basic) !important;

      }
    }
  }

  &__button--editing {
    display: flex !important;
    align-items: center;
    justify-content: space-around;
    padding: 10px 0;


    & span {
      &:hover {
        color: var(--color-dark-basic)
      }
    }
  }

}