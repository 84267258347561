
.addParticipant {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--color-blue-basic);
  font-size: 20px;

  &__title {
    font-size: 32px;
  }
  &__container {
    padding: 60px 60px 0 60px;

    .ParticipantSelector__selected {
      margin-top: 30px;
    }
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 71px;
    padding: 25px;
    border-top: 1px solid var(--color-dark-lighten);
  }
  &__close {
    position: absolute;
    top: 24px;
    right: 17px;
    font-size: 24px;
    color: var(--color-grey-basic);
    cursor: pointer;

    &:hover {
      color: var(--color-dark-basic);
    }
  }

  &__button {
    &--cancel {
      margin-right: 6px;
    }
  }
}